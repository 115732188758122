import { Helmet } from 'react-helmet';
import { clipTextEllipsis } from 'Utils/textEllipsis';
import React from 'react';

const HomePageMeta = ({ metaTitle, metaDescription, baseUrl }) => {
  return (
    <Helmet>
      <title>{clipTextEllipsis(metaTitle, 60)}</title>
      <meta name="twitter:title" content={clipTextEllipsis(metaTitle, 70)} />
      <meta property="og:title" content={clipTextEllipsis(metaTitle, 50)} />
      <meta name="description" content={metaDescription} />
      <meta name="twitter:description" content={clipTextEllipsis(metaDescription, 200)} />
      <meta property="og:description" content={clipTextEllipsis(metaDescription, 160)} />
      <link rel="canonical" href={`${baseUrl}/`} />
    </Helmet>
  );
};

export default HomePageMeta;
